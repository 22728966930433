import React, {useCallback} from 'react';
import { createUseStyles } from 'react-jss';

import { useMedia } from '../useMedia';
import {useSessionStorage} from '../useSessionStorage';

const useFooterAlertStyles = createUseStyles({
    container: {
        height: 180,
        width: '100%'
    },
    bar: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        // height: 130,
        height: 175,
        backgroundColor: '#D60000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 46,
        paddingRight: 46,
        maxWidth: '100vw'
    },
    close: {
        position: 'absolute',
        right: 16,
        top: 16,
        width: 32,
        height: 32,
        opacity: .3,
        backgroundColor: "transparent !important",
        border: "none !important",
        outline: "none !important",
        overflow: 'visible',
        cursor: 'pointer',
        '&:hover': {
            opacity: 1
        },
        '&:before, &:after': {
            position: 'absolute',
            top: 0,
            left: 15,
            content: '""',
            height: 33,
            width: 2,
            backgroundColor: '#FFF'
        },
        '&:before': {
            transform: 'rotate(45deg)'
        },
        '&:after': {
            transform: 'rotate(-45deg)'
        }
    },
    message: {
        color: "#FFF",
        fontFamily: "'Crimson Text', serif",
        maxWidth: '55ch',
        margin: 'auto',
        fontSize: 20,
        '@media (min-width: 300px)': {
            fontSize: 'calc(7.365px + 2.545vmin)'
        },
        '@media (min-width: 575px)': {
            fontSize: 22
        }
    }
});

export const FooterAlert = () => {
    const classes = useFooterAlertStyles();
    const [hidden, setHidden] = useSessionStorage('winter-weather-jan-2024');

    const close = useCallback(() => {
        setHidden(true);
    }, [setHidden]);

    return !hidden && (
        <div className={classes.container}>
            <div className={classes.bar}>
                <div className={classes.message}>Cornerstone Community Church WILL be gathering for worship this Sunday, January 21th, at 10:15am. We will NOT be gathering for Sunday School but will wait another week before starting Sunday School to give everyone more time and safer driving conditions on Sunday.</div>
                <button className={classes.close} onClick={close}/>
            </div>
        </div>
    );
};
