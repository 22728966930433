import React, { useEffect, useState, Suspense } from 'react';

export const Loading = () => null;

export const SuspenseWithTimeout = (props) => {
    const {fallback, ms, children} = props;
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setVisible(true);
        }, ms);

        return () => {
            clearTimeout(timeout);
        };
    }, [ms]);

    return (
        <Suspense fallback={visible && fallback} children={children} />
    );
};