import React, { useRef, useState, useEffect, useMemo } from 'react';

export const useMedia = (query) => {
    const mediaQueryList = useRef();

    useMemo(() => {
        mediaQueryList.current = window.matchMedia(query);
    }, [query]);

    const [, setMatches] = useState(mediaQueryList.current.matches);

    useEffect(() => {
        const updateMatches = () => {
            setMatches(mediaQueryList.current.matches);
        };

        mediaQueryList.current.addListener(updateMatches);

        return () => {
            mediaQueryList.current.removeListener(updateMatches);
        };
    }, [query]);

    return mediaQueryList.current.matches;
};