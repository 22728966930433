import React, { lazy } from 'react';

// react-router
import { Route, Redirect, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';

// internal components
import { Loading, SuspenseWithTimeout } from './loading';

import { NavBar } from './components/navbar';
import {FooterAlert} from './components/footer-alert';

const Home = lazy(() => import('./home'));
const About = lazy(() => import('./about'));
const Visitors = lazy(() => import('./visitors'));
const SingleSermon = lazy(() => import('./components/single-sermon'));
const SingleLesson = lazy(() => import('./components/single-lesson'));
const SermonsSearch = lazy(() => import('./components/sermons-search'));
const LessonsSearch = lazy(() => import('./components/lessons-search'));
const PastoralApprenticeshipPage = lazy(() => import ('./visitors/pastoral-apprenticeship'));
const Search = lazy(() => import('./components/search'));

export const App = () => {

  return (
    <>
      <Router>
        <SuspenseWithTimeout ms={500} fallback={<Loading />}>
          <NavBar/>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route exact path="/sermons" component={SermonsSearch}/>
            <Route path="/sermons/:sermonSlug" component={SingleSermon}/>
            <Route exact path="/lessons" component={LessonsSearch}/>
            <Route path="/lessons/:lessonSlug" component={SingleLesson}/>
            <Redirect from="/visitors/pastoral-internship" to="/pastoral-apprenticeship" />
            <Redirect from="/pastoral-internship" to="/pastoral-apprenticeship" />
            <Route path="/pastoral-apprenticeship" component={PastoralApprenticeshipPage} />
            <Route path="/visitors" component={Visitors} />
            <Route path="/about" component={About} />
            <Route path="/search" component={Search} />
            {/* NOTE: Removing the donate route for the present time. */}
            {/*<Route path="/donate" component={Home} />*/}
            <Redirect to="/" />
          </Switch>
          {/* <FooterAlert/> */}
        </SuspenseWithTimeout>
      </Router>
    </>
  );
};