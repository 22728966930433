import React from 'react';
import { NavLink } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';

import { useMedia } from '../useMedia';

import cornerstoneLogo from '../images/cornerstone-community-church-logo.svg';
import magnifier from '../images/magnifier.svg';

const useNavBarLinkStyles = createUseStyles({
    activeNavLink: {
        "&:before": {
            content: '""',
            position: "absolute",
            left: 14,
            right: 14,
            bottom: 0,
            height: 3.5,
            backgroundColor: "#002247",
            "@media (min-width: 426px)": {
                left: 16,
                right: 16,
                height: 4
            }
        }
    },
    navLink: {
        display: "inline-block",
        position: "relative",
        paddingLeft: 14,
        paddingRight: 14,
        fontSize: 21,
        color: "#002247",
        fontWeight: 300,
        verticalAlign: "middle",
        textDecoration: "none",
        fontFamily: "'Spectral SC', serif",
        lineHeight: "56px",
        "@media (min-width: 426px)": {
            lineHeight: "64px",
            paddingLeft: 16,
            paddingRight: 16,
            fontSize: 24
        }
    }
});

export const ExternalNavLink = (props) => {
    const {
        to: href = '',
        className,
        ...otherProps
    } = props;

    const classes = useNavBarLinkStyles();

    return (
        <a href={href} className={classnames(classes.navLink, className)} {...otherProps} />
    );
};

export const NavBarLink = (props) => {
    const classes = useNavBarLinkStyles();

    return (
        <NavLink activeClassName={classes.activeNavLink} {...props} className={classnames(classes.navLink, props.className)} />
    );
};

const useNavBarStyles = createUseStyles({
    navBar: {
        height: 56,
        position: "relative",
        boxShadow: "0 0 4px rgba(0, 0, 0, 0.5)",
        maxWidth: "100%",
        overflow: "auto",
        paddingLeft: 16,
        paddingRight: 15,
        textAlign: "right",
        whiteSpace: "nowrap",
        "@media (min-width: 426px)": {
            height: 64
        }
    },
    navBarImageContainer: {
        maxWidth: 200,
        height: "100%",
        boxSizing: "border-box",
        position: "relative",
        paddingBottom: 12,
        paddingLeft: 17,
        paddingTop: 9,
        float: "left"
    },
    navBarLogo: {
        width: "auto",
        height: "100%",
        display: "block"
    },
    navBarSearchIcon: {
        height: 21,
        width: 21,
        verticalAlign: "middle",
        display: "inline-block",
        lineHeight: "56px",
        "@media (min-width: 426px)": {
            lineHeight: "64px",
            height: 24,
            width: 24
        }
    },
    searchNavBarLink: {
        paddingLeft: 12,
        paddingRight: 26,
        "@media (min-width: 426px)": {
            paddingLeft: 14,
            paddingRight: 30
        },
        "&:before": {
            left: 4,
            right: 12
        }
    }
});

export const NavBar = () => {
    const classes = useNavBarStyles();

    const isDesktop = useMedia('(min-width: 1200px)');

    return (
        <div className={classes.navBar}>
            {isDesktop && (
                <div className={classes.navBarImageContainer}>
                    <img src={cornerstoneLogo} alt="Cornerstone Community Church" className={classes.navBarLogo} />
                </div>
            )}
            <NavBarLink exact to="/">Home</NavBarLink>
            <NavBarLink to="/sermons">Sermons</NavBarLink>
            <NavBarLink to="/lessons">Lessons</NavBarLink>
            <NavBarLink to="/visitors">Visitors</NavBarLink>
            <NavBarLink to="/pastoral-apprenticeship">Apprenticeship</NavBarLink>
            <NavBarLink to="/about">About</NavBarLink>
            <ExternalNavLink to="https://cccjackson.breezechms.com/give/online">Give</ExternalNavLink>
            {/* NOTE: Removing the donate route for the present time. */}
            {/*<NavBarLink to="/donate">Donate</NavBarLink>*/}
            <NavBarLink to="/search" className={classes.searchNavBarLink}>
                <img src={magnifier} alt="Search" className={classes.navBarSearchIcon} />
            </NavBarLink>
        </div>
    );
};
